<template>
        <b-row>
            <b-col md="12" sm="12">
                <div class="card card-custom card-stretch gutter-b">
                    <div class="card-header border-0 py-5">
                      <h3 class="card-title">
                        <span class="card-label font-weight-bolder text-dark" style="display: flex; align-items: center;">
                          Evento
                          <span class="text-muted font-weight-bold font-size-sm">
                             <b-form-select size="sm" v-model="perPage" :options="PageOptions" :clearable="false" class="ml-2" />
                          </span>
                        </span>
                      </h3>
                      <div class="pr-5 mt-3">
                        <b-row style="display: flex;">
                            <b-form-input
                            style="width: 300px;"
                              v-model="searchTerm"
                              class="d-inline-block mr-5"
                              placeholder="Buscar Evento ..."
                              @input="handleSearch"
                            />
                            <b-button 
                              v-b-toggle.event-sidebar
                              @click="Create"
                            >
                              Agregar Evento
                            </b-button>
                        </b-row>
                      </div>
                    </div>
                    <div class="card-body py-0">
                      <b-table
                        ref="tableInvitados"
                        :items="itemEvents"
                        :fields="fields"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection"
                        responsive
                        @sort-changed="onSortChange"
                        style="position: relative !important; height: 500px !important;"
                      >
                        
                        <template #cell(name)="data">
                            <b-media vertical-align="center">
                              <!-- @click="NavigateUserProfile(data.item.IdUser)" -->
                              <span class="font-weight-bold text-nowrap">
                                {{ data.value }}
                              </span>
                            </b-media>
                        </template>

                        <template #cell(date)="data">
                          <span class="text-nowrap">
                            <b-avatar
                              class="border mr-1"
                              size="20"
                              icon="calendar"
                            />
                            {{ data.value | date-format }}
                          </span>
                        </template>

                        <template #cell(slugs)="data">
                          <span class="text-nowrap">
                            <span 
                            v-for="(slug, index) in data.value"
                            :key="index"
                            class="badge badge-dark mr-2">{{ slug.slug }}</span>
                          </span>
                        </template>

                        <template #cell(groupType)="data">
                          <span class="text-nowrap">
                            <b-avatar
                              class="border mr-1"
                              size="20"
                              :icon="IconCatalog(data.value)"
                            />
                            {{ data.value }}
                          </span>
                        </template>

                        <template #cell()="data">
                          <span class="text-nowrap">
                            {{ data.value }}
                          </span>
                        </template>
                        <template #cell(Actions)="data">
                          <span class="text-nowrap">
                            <a
                              href="#"
                              class="btn btn-icon btn-light btn-hover-primary btn-hover-bedo btn-sm mx-3 pulse"
                              @click="seeEvent(data.item.id)"
                            >
                              <span class="svg-icon svg-icon-md svg-icon-primary">
                                <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                                <i class="far fa-eye"></i>
                                <!--end::Svg Icon-->
                              </span>
                            </a>
                            <a
                              href="#"
                              class="btn btn-icon btn-light btn-hover-primary btn-hover-bedo btn-sm mx-3 pulse"
                              v-b-toggle.event-sidebar
                              @click="getDataofEvent(data.item.id)"
                            >
                              <span class="svg-icon svg-icon-md svg-icon-primary">
                                <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                                <i class="las la-pencil-alt"></i>
                                <!--end::Svg Icon-->
                              </span>
                            </a>
                            <a
                              href="#"
                              class="btn btn-icon btn-light btn-hover-primary btn-hover-bedo btn-sm pulse"
                              @click="DeleteEvent(data.item.id)"
                            >
                              <span class="svg-icon svg-icon-md svg-icon-primary">
                                <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                                <inline-svg src="media/svg/icons/General/Trash.svg" />
                                <!--end::Svg Icon-->
                              </span>
                            </a>
                          </span>
                        </template>
                  
                      </b-table>
                  
                      <div class="border-0 py-5">
                        <b-row>
                          
                          <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-start"
                          >
                            <span class="text-muted">
                                Showing {{ perPage }} of {{ FullCountEntries }} entries
                            </span>
                          </b-col>
                         
                          <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-end"
                          >
                            <b-pagination
                              pills
                              v-model="currentPage"
                              :total-rows="totalRows"
                              :per-page="perPage"
                              :current-page="currentPage"
                              first-number
                              last-number
                              class="mb-0 mt-1 mt-sm-0"
                              prev-class="prev-item"
                              next-class="next-item"
                              @change="handleChangePage"
                            >
                              <template #prev-text>
                                <i class="fa fa-angle-left"></i>
                              </template>
                              <template #next-text>
                                <i class="fa fa-angle-right"></i>
                              </template>
                             
                            </b-pagination>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                </div>
            </b-col>
            <!--begin::View component-->
            <evento-sidebar 
              :refresh-data="getEvents"
              :get-data="getDataEvent"
            />
        </b-row>
</template>

<script>
import eventoSidebar from '../SideBars/EventSidebar.vue'
import { GET_ALL_EVENTS, GET_EVENT_INFO, DELETE_EVENT } from "@/core/services/store/event.module";
import { SAVE_EVENT_ID, GET_EVENT_PACKAGE} from "@/core/services/store/invitation.module";
import Swal from 'sweetalert2'
export default {
    /* eslint-disable */
    components: {
      eventoSidebar,
    },
    data() {
        return {
            itemEvents: [],
            fields: [{ key: 'name', label: 'Nombre del Evento', sortable: true }, { key: 'date', label: 'FechaEvento', sortable: true }, { key: 'clientPhone', label: 'Telefono', sortable: true }, { key: 'clientEmail', label: 'Email', sortable: true }, { key: 'groupType', label: 'Grupo', sortable: true }, { key: 'packageName', label: 'Paquetes', sortable: true }, { key: 'slugs', label: 'Carpetas', sortable: true }, { key: 'Actions', label: 'Actions' }],
            perPage: 10,
            PageOptions: [5, 10, 25, 50, 100],
            actualpage: 1,
            totalRows: 0,
            currentPage: 1,
            FullCountEntries: 0,
            sortBy: 'name',
            sortDesc: false,
            sortDirection: 'asc',
            searchTerm: '',
            getDataEvent: {},
        }
    },
    watch: {
      perPage() {
        this.getEvents()
      },
    },
    created() {
      this.getEvents()
    },
    methods: {
      Create() {
        this.getDataEvent = {}
      },
      seeEvent(id){
        this.$store.dispatch(GET_EVENT_PACKAGE,{
          idEvent: id,
        }).then((response) => {
          this.$store.dispatch(SAVE_EVENT_ID, {
            paramsEvent: response.data.data,
          })
        }).then(() => {
          this.$router.push({ name: 'client-dashboard'})
        })
      },
      getDataofEvent(id) {
        this.$store.dispatch(GET_EVENT_INFO, {
          id
        })
        .then((response) => {
          response.data.data.idevent = id
          this.getDataEvent = response.data.data
        })
      },
      getEvents() {
        this.$store.dispatch(GET_ALL_EVENTS, {
          name: this.searchTerm,
          page: this.actualpage,
          rows: this.perPage,
          orderBy: this.sortBy,
          sortBy: this.sortDirection,
        })
        .then((response) => {
          if(response.data.data.length !== 0){
            this.itemEvents = response.data.data
            this.FullCountEntries = response.data.data[0].full_count
            this.numPages = response.data.data[0].pages
            this.totalRows = this.FullCountEntries
            this.currentPage = this.actualpage
          }
        })
      },
      DeleteEvent(id) {
        Swal.fire({
          title: 'Atención',
          text: '¿Deseas Borrar este evento?',
          icon: 'warning',
          confirmButtonText: 'Si',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
        }).then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch(DELETE_EVENT, {
              id
            })
            .then(() => {
              this.getEvents()
              Swal.fire(
                'Exitoso',
                'Se ha Eliminado!',
                'success',
              )
            })
            
          }
        })
      },
      handleSearch(searching) {
        this.searchTerm = searching
        this.actualpage = 1
        this.totalRows = 1
        this.getEvents()
      },
      handleChangePage(page) {
        this.actualpage = page
        this.getEvents()
      },
      onSortChange(params) {
        this.sortBy = params.sortBy
        this.sortDesc = params.sortDesc
        const direction = params.sortDesc === true ? 'desc' : 'asc'
        this.sortDirection = direction
        this.getEvents()
      },
      /* navigateToDesignTemplate() {
        // this.$store.commit('access-level/setIdAccessLevel', id)
        this.$router.push({ name: 'theme-design' })
      }, */
      IconCatalog(group){
        switch(group){
          case 'Cumpleaños':
            return 'gift-fill'
            break;
          case 'Bodas':
            return 'suit-heart-fill'
            break;
          case '15 Años':
            return 'headphones'
            break;
          default:
            break;
        }
      }
    },
}
</script>

<style scoped>
.GuestsConfirm{
  color: #52DD26 !important; 
  background-color: rgba(82, 221, 38, 0.1) !important;
}

.GuestsDenied{
  color: #DD2C26 !important; 
  background-color: rgba(221, 44, 38, 0.1) !important;
}

.btn-hover-bedo:hover{
  background-color: rgb(134, 160, 182) !important; 
  border-color: transparent !important; 
  color: white !important;
}
</style>