<template>
    <b-sidebar
      id="event-sidebar"
      shadow
      right
      bg-variant="white"
      backdrop
      width="400px"
      @hidden="cleanEventSidebar"
    >
      <template #default="{ hide }">
        <div style="padding: 35px;">
        <span class="text-dark-75 font-weight-bolder text-hover-primary" style="font-size: 15px;">
          {{ Object.keys(getData).length === 0 ? 'Agregar Evento' : 'Editar Evento' }}
        </span>

        <b-row class="mt-5">
          <b-col sm="12">
            <b-form-group
              label="Titulo del Evento"
              label-for="Titulo del Evento"
            >
             <b-form-input
               v-model="NameEvent"
               type="text"
               class="form-control-merge"
               placeholder="Nombre"
             />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Fecha del Evento"
              label-for="Fecha del Evento"
            >
              <b-form-datepicker
                v-model="DateEvent"
                id="datepicker-buttons"
                today-button
                reset-button
                close-button
                placeholder="Seleccionar Fecha"
              />
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <div style="color: red; font-size: 10px;" v-if="Object.keys(getData).length > 0">La edicion no afectara este valor</div>
            <b-form-group
              label="Selecciona el Cliente"
              label-for="Selecciona el Cliente"
            >
             <b-form-select
               v-model="ClientSelected"
               :options="ClientList"
               value-field="id"
               text-field="name"
              >
             </b-form-select> 
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <div style="color: red; font-size: 10px;" v-if="Object.keys(getData).length > 0">La edicion no afectara este valor</div>
            <b-form-group
              label="Tipo de Paquete"
              label-for="Tipo de Paquete"
            >
             <b-form-select
               v-model="PaqueteSelected"
               :options="PaqueteList"
               value-field="id"
               text-field="name"
              >
             </b-form-select> 
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
              label="Tipo de Grupo"
              label-for="Tipo de Grupo"
            >
             <b-form-select
               v-model="GroupSelected"
               :options="GroupList"
               value-field="id"
               text-field="name"
              >
             </b-form-select> 
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
              label="Tipo de Formulario/Invitado"
              label-for="Tipo de Formulario/Invitado"
            >
             <b-form-select
               v-model="TypeGuestSelected"
               :options="TypeGuestList"
               value-field="id"
               text-field="name"
              >
             </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
              label="Acompañantes x Invitado"
              label-for="Acompañantes x Invitado"
            >
             <b-form-spinbutton id="demo-sb" v-model="Escort" min="1" max="100"></b-form-spinbutton>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
              label="Slug/Carpeta"
              label-for="Titulo del Evento"
            >
              <b-input-group
                class="mb-5"
              >
                <b-form-input
                  v-model="SlugAddName"
                  type="text"
                  size="sm"
                  class="form-control-merge"
                  :formatter="formatter"
                />
                <b-input-group-append>
                  <a
                    href="#"
                    class="btn btn-icon btn-light btn-hover-primary btn-sm pulse"
                    @click="AddSlug()"
                  >
                    <i class="fa fa-plus fs-2x me-15"></i>
                  </a>
                </b-input-group-append>
              </b-input-group>
              <b-input-group
                v-for="(slug, index) in Slugs"
                :key="index"
                class="mb-1"
              >
                <b-form-input
                  :formatter="formatter"
                  v-model="slug.name"
                  type="text"
                  size="sm"
                  class="form-control-merge"
                />
                <b-input-group-append>
                  <a
                    href="#"
                    class="btn btn-icon btn-light btn-hover-primary btn-sm pulse"
                    @click="DeleteSlug(index)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                      <inline-svg src="media/svg/icons/General/Trash.svg" />
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                </b-input-group-append>
              </b-input-group>
          </b-form-group>
          </b-col>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button 
              @click="CreateEvent"
            >
              {{ Object.keys(getData).length === 0 ? 'Agregar' : 'Editar' }}
            </b-button>
            <b-button
              ref="closeSideBarEvents"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-row>
     </div>
      </template>
    </b-sidebar>
</template>


<script>
//
import { GET_ALL_CLIENTS_TO_SELECT, GET_ALL_PACKAGE_TO_SELECT, GET_ALL_GROUP_TO_SELECT, GET_ALL_TYPE_GUEST_TO_SELECT, CREATE_EVENT, EDIT_EVENT } from "@/core/services/store/event.module";
import moment from 'moment';
import Swal from 'sweetalert2'

export default {
  components: {
  },
  data() {
    return{
      SlugAddName: '',
      Slugs: [],
      idEvent: 0,
      NameEvent: '',
      DateEvent: null,
      ClientSelected: null,
      ClientList:  [],
      PaqueteSelected: null,
      PaqueteList:  [],
      GroupSelected: null,
      GroupList:  [],
      TypeGuestSelected: null,
      TypeGuestList:  [],
      Escort: 5,
      SlugCarpeta: '',
    }
  },
  props: {
    refreshData: {
      type: Function,
      required: true,
    },
    getData: {
      type: Object,
      required: true,
    },
  },
  watch: {
    getData(val) {
      if (Object.keys(val).length !== 0) {
        this.NameEvent = val.title
        this.DateEvent = val.date
        this.ClientSelected = val.client.id
        this.PaqueteSelected = val.package.id
        this.GroupSelected = val.groupType.id
        this.TypeGuestSelected = val.guestType.id
        this.Escort = val.escortsLimit
        this.idEvent = val.idevent
        this.Slugs = val.slugs
      } else {
        this.NameEvent = ''
        this.DateEvent = null
        this.ClientSelected = null
        this.PaqueteSelected = null
        this.GroupSelected = null
        this.TypeGuestSelected = null
        this.Escort = 5
        this.idEvent = 0
        this.Slugs = []
      }
    },
  },
  created() {
    this.getAllClientsToSelect()
    this.getAllPackageToSelect()
    this.getAllGroupToSelect()
    this.getAllTypeGuestToSelect()
  },
  methods: {
    formatter(value) {
      return value.toLowerCase()
    },
    AddSlug(){
      if(this.SlugAddName !== ''){
        this.Slugs.push({ name: this.SlugAddName })
        this.SlugAddName = ''
      }
    },
    DeleteSlug(index){
      this.Slugs.splice(index, 1)
    },
    getAllTypeGuestToSelect() {
      this.$store.dispatch(GET_ALL_TYPE_GUEST_TO_SELECT, {})
      .then((response) => {
        this.TypeGuestList = response.data.data
      })
    },
    getAllGroupToSelect() {
      this.$store.dispatch(GET_ALL_GROUP_TO_SELECT, {})
      .then((response) => {
        this.GroupList = response.data.data
      })
    },
    getAllPackageToSelect() {
      this.$store.dispatch(GET_ALL_PACKAGE_TO_SELECT, {})
      .then((response) => {
        this.PaqueteList = response.data.data
      })
    },
    getAllClientsToSelect() {
      this.$store.dispatch(GET_ALL_CLIENTS_TO_SELECT, {})
      .then((response) => {
        this.ClientList = response.data.data
      })
    },
    validateInputs() {
      let Text = ''
      let Error = ''
      if (this.NameEvent === '') {
        Text += '- Nombre del Evento Falta<br>'
      } else {
        Text += ''
      }
      if (this.DateEvent === null) {
        Text += '- Fecha del Evento Falta<br>'
      } else {
        Text += ''
      }
      if (this.ClientSelected === null) {
        Text += '- Falta asignar un cliente al evento<br>'
      } else {
        Text += ''
      }
      if (this.PaqueteSelected === null) {
        Text += '- Tipo de Paquete falta<br>'
      } else {
        Text += ''
      }
      if (this.GroupSelected === null) {
        Text += '- Tipo de Grupo Falta<br>'
      } else {
        Text += ''
      }
      if (this.TypeGuestSelected === null) {
        Text += '- Tipos de invitado Falta<br>'
      } else {
        Text += ''
      }
      if (this.Slugs.length === 0) {
        Text += '- Slugs Faltan<br>'
      } else {
        this.Slugs.forEach((slugs, index) => {
          this.Slugs[index].name = slugs.name.trim()
          if(slugs.name == ''){
            Text += `Slug Vacio `
          } else {
            if(/^[a-z0-9-_]*$/.test(slugs.name)) {
              Text += ''
            } else {
              Error += `"${slugs.name}", `
            }
          }
        })
        if (Error !== '') {
          Text += `Error en ${Error} (solo numeros, letras, guion, guion bajo y sin epacios) `
        } else {
          Text += ''
        }
      }
      if (Text !== '') {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          html: `<div class="lbedo">${Text}</div>`,
          showConfirmButton: false,
        })
        return false
      } else {
        return true
      }
    },
    CreateEvent(){
      if(this.validateInputs()){
        if (Object.keys(this.getData).length === 0) {
          this.$store.dispatch(CREATE_EVENT, {
            title: this.NameEvent,
            date: moment(this.DateEvent).format('YYYY-MM-DD'),
            idClient: this.ClientSelected,
            idPackage: this.PaqueteSelected,
            idGroup: this.GroupSelected,
            idGuestType: this.TypeGuestSelected,
            escortsLimit: this.Escort,
            slugs: this.Slugs
          })
          .then(() => {
            this.$refs.closeSideBarEvents.click()
            this.refreshData()
          })
        } else {
          this.$store.dispatch(EDIT_EVENT, {
            id: this.idEvent,
            title: this.NameEvent,
            date: moment(this.DateEvent).format('YYYY-MM-DD'),
            idClient: this.ClientSelected,
            idPackage: this.PaqueteSelected,
            idGroup: this.GroupSelected,
            idGuestType: this.TypeGuestSelected,
            escortsLimit: this.Escort,
            slugs: this.Slugs
          })
          .then(() => {
            this.$refs.closeSideBarEvents.click()
            this.refreshData()
          })
        }
      }
    },
    cleanEventSidebar(){
      this.NameEvent = ''
      this.DateEvent = null
      this.ClientSelected = null
      this.PaqueteSelected = null
      this.GroupSelected = null
      this.TypeGuestSelected = null
      this.Escort = 5
      this.Slugs = []
    }
  }
}
</script>